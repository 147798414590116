import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className="justify-content-center  d-flex">
    
    <div className="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
<div className="container pt-5 pb-4">
    <div className="row g-5 ">
        <div className="col-lg-4  col-md-6">
            <Link to={'/'} className="text-decoration-none">
                <h1 className="text-white text-decoration-none fw-bold d-block">Nxt<span className="text-secondary">Constructions</span> </h1>
            </Link>
            <div className="mt-4 d-flex flex-column short-link">
            <Link  className="mb-2 text-white text-decoration-none text-decoration-none"><i className="fas fa-angle-right text-secondary me-2  "></i>Building Dreams & Constructing Futures.</Link>
            <Link  className="mb-2 text-white text-decoration-none "><i className="fas fa-angle-right text-secondary me-2"></i>Crafting Excellence in Construction.</Link>
            <Link  className="mb-2 text-white text-decoration-none "><i className="fas fa-angle-right text-secondary me-2"></i>Where Vision Meets Structure.</Link>
            
        </div>
            <div className="social-links mt-3">
              <Link to={'https://t.me/Nxt_con'} className="twitter text-decoration-none mx-1"><i className="bi bi-telegram h1"></i></Link>
              <Link to={'https://www.facebook.com/profile.php?id=61557153863957'} className="facebook text-decoration-none mx-1"><i className="bi bi-facebook h1"></i></Link>
              <Link  className="instagram text-decoration-none mx-1"><i className="bi bi-instagram h1"></i></Link>

            </div>
        </div>
        <div className="col-lg-4 col-md-6">
            <Link href="#" className="h3 text-white">Pages</Link>
            <div className="mt-4 d-flex flex-column short-link">
                <Link to={'/about'} className="mb-2 text-white text-decoration-none text-decoration-none"><i className="fas fa-angle-right text-secondary me-2  "></i>About us</Link>
                <Link to={'/contact'} className="mb-2 text-white text-decoration-none "><i className="fas fa-angle-right text-secondary me-2"></i>Contact us</Link>
                <Link to={'/about'}  className="mb-2 text-white text-decoration-none "><i className="fas fa-angle-right text-secondary me-2"></i> Team</Link>
                <Link to={'/portfolio'} className="mb-2 text-white text-decoration-none "><i className="fas fa-angle-right text-secondary me-2"></i>Projects</Link>
                <Link to={'/blog'} className="mb-2 text-white text-decoration-none "><i className="fas fa-angle-right text-secondary me-2"></i> Blog</Link>
            </div>
        </div>
       
        <div className="col-lg-4 col-md-6">
            <Link className="h3 text-white ">Contact Us</Link>
            <div className="text-white text-decoration-none mt-4 d-flex flex-column contact-link">
                <Link className="pb-3 text-light border-bottom border-primary text-decoration-none"><i className="fas fa-map-marker-alt text-secondary me-2"></i> Lebu, Addis Ababa, Ethiopia</Link>
                <Link  className="py-3 text-light border-bottom border-primary text-decoration-none"><i className="fas fa-phone-alt text-secondary me-2"></i> +251974947373</Link>
                <Link  className="py-3 text-light border-bottom border-primary text-decoration-none"><i className="fas fa-envelope text-secondary me-2"></i> tekrim17@gmail.com</Link>
            </div>
        </div>
    </div>
   
</div>
</div>
    </div>
  )
}

export default Footer