import React, { useEffect, useState } from 'react'



import { Link } from 'react-router-dom'
import axios from 'axios';
function Homeblog() {
  const [data, setdata] = useState([]);
  useEffect(() => {
      axios.get("https://backend.nxtconstructions.gov.et/blogfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdata(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 3))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);
  

  return (
    <div>
    
    {data.length>0 && <section id="recent-blog-posts" className="recent-blog-posts">

      <div className="container" data-aos="fade-up">

        <div className="m-5 text-center ">
        <h4 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">Latest Blog <span className='text-primary'>& News</span></h4>
      
        </div>

        <div className="row ">

        {data.map((blog,i)=>{
          return   <div className="col-lg-4" key={i}>
            <div className="post-box">
            <div className="post-img"><img src={'https://backend.nxtconstructions.gov.et/images/' + blog.image} className="img-fluid" alt=""/></div>
              <span className="post-date">{blog.date}</span>
              <h3 className="post-title text-justify text-dark">{blog.subject}</h3>
              <div className="read-more d-flex justify-content-end ">
              <Link to={`/blogdetail/${blog.id}`} className="text-decoration-none ">Read Detail</Link>
            </div>
            </div>
          </div>
        }) }
      

        </div>
       
      </div>
      
    </section>}
    </div>
  )
}

export default Homeblog