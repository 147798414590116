import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import axios from 'axios'
function Blog() {
  const [data, setdata] = useState([]);
  const [latest, setlatest] = useState([]);
  useEffect(() => {
      axios.get("https://backend.nxtconstructions.gov.et/blogfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdata(res.data.Result.sort((a, b) => b.id - a.id))
      setlatest(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 4))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);

 
  const truncate = (str, len) => str.slice(0, len);

  return (
    <div >
    <div class="page-nav page-header no-margin w-100 mx-0 row">
    <div class="container w-100">
        <div class="row">
        <h2 class="text-white ">Nxt Constructions</h2>
            <ul>
                <li > <Link to={'/'} class="text-white ld"><i class="bi bi-caret-right text-white"></i>Home</Link></li>
                <li class="text-white "><i class="bi bi-caret-right text-white"></i>Blog</li>
            </ul>
        </div>
    </div>
</div>

<div className="text-center m-5 wow fadeInUp" data-wow-delay=".3s">
<h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">Our Blogs</h5>

</div>

   {data.length>0 && <section id="blog" class="blog ">
    <div class="container" data-aos="fade-up">

      <div class="row">

        <div class="col-lg-8 entries">

           {data.map((blog,i)=>{
     return     <article class="entry" key={i}>

            <div class="entry-img">
              <img src={'https://backend.nxtconstructions.gov.et/images/' + blog.image} alt="" class="img-fluid"/>
            </div>

            <h2 class="entry-title">
              <Link class="text-decoration-none text-dark">{blog.subject}</Link>
            </h2>

            <div class="entry-meta">
              <ul>
               
                <li class="d-flex align-items-center"><i class="bi bi-clock"></i> <Link class="text-decoration-none "><time datetime="2020-01-01">{blog.date}</time></Link></li>

              </ul>
            </div>

            <div class="entry-content">
              <p>
           {truncate(blog.detail, 230)}..... 
              </p>
              <div class="read-more">
                <Link to={`/blogdetail/${blog.id}`} class="text-decoration-none ">Read More</Link>
              </div>
            </div>

          </article>
        }) }
         

      

        </div>

        <div class="col-lg-4 mr-0 px-0">

          <div class="sidebar mx-0">

            


            <h3 class="sidebar-title text-dark ">Recent Posts</h3>
            <div class="sidebar-item recent-posts">
            {latest.map((blog,i)=>{
              return   <div class="post-item clearfix" key={i}>
             <Link to={`/blogdetail/${blog.id}`} className='text-decoration-none '>
                <img src={'https://backend.nxtconstructions.gov.et/images/' + blog.image} alt=""/>
                <h4><h6 class="text-decoration-none text-dark">{blog.subject}</h6></h4>
                <time datetime="2020-01-01">{blog.date}</time>
                </Link>
              </div>
            }) }
          
             

      


          </div>

        </div>

      </div>

    </div>
    </div>
  </section>}
   
    
    </div>
  )
}

export default Blog