import React, { useEffect, useState } from 'react'
import axios from 'axios';

import { Link } from 'react-router-dom'
function Projects() {


    const [data, setdata] = useState([]);

 
    useEffect(() => {
        axios.get("https://backend.nxtconstructions.gov.et/portfoliofetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);


  return (
    <div>
    <div class="page-nav page-header no-margin w-100 mx-0 row">
    <div class="container w-100">
        <div className="row">
        <h2 className="text-white ">Nxt Constructions</h2>
            <ul>
                <li > <Link to={'/'} className="text-white ld"><i className="bi bi-caret-right text-white"></i>Home</Link></li>
                <li className="text-white "><i className="bi bi-caret-right text-white"></i>Projects</li>
            </ul>
        </div>
    </div>
</div>
    

    <div className="container-fluid py-5">
    <div className="container py-3">
        <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
            <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">Our Projects</h5>

        </div>

        <div className="row g-5">
     {data.map((project,i)=>{
       return  <div className="col-xxl-4 col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay=".3s" key={i}>
           <div className="project-item">
               <div className="project-left bg-dark"></div>
               <div className="project-right bg-dark"></div>
               <img src={'https://backend.nxtconstructions.gov.et/images/' + project.image} className="img-fluid h-100" alt="img"/>
              
               <Link  className="fs-4 fw-bold  nnnbbjj text-center d-flex  flex-column nblure">
               <h3 className='text-white'><b><i className="bi bi-buildings"></i>  {project.name}</b></h3>
             
               <h6 className='text-white'><b><i className="bi bi-geo-alt-fill"></i>  {project.location}</b></h6>
               
               
               </Link>
           </div>
       </div>
        


     })}





        </div>
    </div>
</div>
    
    </div>
  )
}

export default Projects