import React from 'react'
import { Link } from 'react-router-dom'
import Team from './Team'

import  about from '../i/logo.jpg'
function About() {
  return (
    <div>
    <div class="page-nav page-header no-margin w-100 mx-0 row">
    <div class="container w-100">
        <div class="row">
        <h2 class="text-white ">Nxt Constructions</h2>
            <ul>
                <li > <Link to={'/'} class="text-white ld"><i class="bi bi-caret-right text-white"></i>Home</Link></li>
                <li class="text-white "><i class="bi bi-caret-right text-white"></i> About Us</li>
            </ul>
        </div>
    </div>
</div>



    
<div class=" py-5">
<div class="container">
  <div class="row g-2">
    <div class="col-lg-6 wow fadeInUp " data-wow-delay="0.1s">
      <div >
        <img
         className='rounded'
          src={about}
          alt=""
          

        />
       
      </div>
    </div>
    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
      <div class="h-100">
        <div class="border-start border-5 border-primary ps-4 mb-3">
          <h6 class="text-body text-uppercase mb-1">About Us</h6>
        
        </div>
        <p>
        Our organization is a dynamic force in the construction industry, committed to excellence and innovation. With a rich legacy spanning decades, we have consistently delivered exceptional services in excavation, earthworks, landscaping, construction machinery rentals, water-proofing works, and finishing projects. Our team of skilled professionals brings passion, expertise, and dedication to every endeavor, ensuring that our clients’ visions become reality. Whether it’s shaping the landscape, constructing robust buildings, or providing specialized machinery, we take pride in our work and strive for sustainable solutions that stand the test of time.
        </p>
       
        <div class="border-top mt-4 pt-4">
          <div class="row g-2">
            <div class="col-sm-4 d-flex wow fadeIn" data-wow-delay="0.1s">
            <i class="bi bi-check-lg h5 text-primary "></i>
              <h6 class="mb-0">Ontime at services</h6>
            </div>
            <div class="col-sm-4 d-flex wow fadeIn" data-wow-delay="0.3s">
            <i class="bi bi-check-lg h5 text-primary "></i>
              <h6 class="mb-0">24/7 hours services</h6>
            </div>
            <div class="col-sm-4 d-flex wow fadeIn" data-wow-delay="0.5s">
            <i class="bi bi-check-lg h5 text-primary "></i>
              <h6 class="mb-0">Verified professionals</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<h2>Vision of Nxt Constructions</h2>
<p>Our vision is to redefine the construction landscape by fostering creativity, sustainability, and collaboration. We envision a future where our projects not only meet functional requirements but also enhance the environment and uplift communities. Through cutting-edge technology, ethical practices, and a commitment to quality, we aim to be industry leaders, setting new standards for excellence. Our vision extends beyond bricks and mortar; it encompasses a legacy of positive impact, leaving a mark that inspires generations to come.
</p>

<h2 className='mt-5'>Our Mission</h2>
<p>Our mission is to provide comprehensive and integrated construction and landscaping services that are tailored to the unique needs and aspirations of our clients. Guided by a commitment to integrity, transparency, and accountability, we strive to forge enduring partnerships built on trust and mutual respect. By leveraging our expertise, resources, and network of industry partners, we aim to deliver cost-effective solutions that maximize value and minimize environmental impact. Through teamwork, innovation, and a relentless pursuit of excellence, we are dedicated to realizing the full potential of every project, leaving a lasting legacy of quality, reliability, and customer satisfaction.
</p>



</div>
</div>
    
    <Team/>
    </div>
  )
}

export default About