import React from 'react'
import { Link } from 'react-router-dom'

function Notfound() {
  return (
    <div className='d-flex align-items-center vh-100'>
    <div className="container text-center">
    <h1>404 - Page Not Found</h1>
    <h6>Oops! The page you are looking for does not exist.</h6>
    <Link className='btn btn-warning ' to={'/'}>Back to Home Page</Link>
  </div>
    
    
    </div>
  )
}

export default Notfound