import React from 'react'
import { Link } from 'react-router-dom'
import  about from '../i/logo.jpg'

function HomeAbout() {
 
  return (
    <div>
    <div className="  ">
    <div className="container pt-3">
   
    <div className="m-2 text-center ">
        <h4 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">About Us</h4>
      
        </div>
        <div className="row ">
            <div className=" col-md-6 mb-0 " data-wow-delay=".3s">
                <div className="  ">
                    <img src={about} className="img-fluid mx-0 rounded d-flex justify-content-center my-1 mt-md-3" alt="" style={{marginBottom: '25%'}}/>
                   
                </div>
            </div>
            <div className=" col-md-6 mt-md-5 mt-0" >
                
                <h1 className="mb-4">About Nxt Constructions </h1>
                <p>Our organization is a dynamic force in the construction industry, committed to excellence and innovation. With a rich legacy spanning decades, we have consistently delivered exceptional services in excavation, earthworks, landscaping, construction machinery rentals, water-proofing works, and finishing projects. Our team of skilled professionals brings passion, expertise, and dedication to every endeavor, ensuring that our clients’ visions become reality. Whether it’s shaping the landscape, constructing robust buildings, or providing specialized machinery, we take pride in our work and strive for sustainable solutions that stand the test of time.</p>
               
                <div className="d-flex   justify-content-center mb-2 mt-2 ">
                <Link to={'/about'} className="btn btn-warning    " style={{borderRadius: '30px '}}>Read More</Link>
                </div>
            </div>
        </div>
    </div>
</div>
    
    
    </div>
  )
}

export default HomeAbout