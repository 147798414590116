import React from 'react'


import  s1 from '../i/s1.jpeg'
import  s2 from '../i/s2.jpeg'
import  s3 from '../i/s3.jpeg'
import  s4 from '../i/f2.jpg'
import  s5 from '../i/s5.jpeg'
function Service() {
  return (
    <div>
    
    <section id="services" className="services my-4">
    <div className='text-center mb-5'>
    <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">Explore Our <span className='text-primary'>SERVICES</span></h5>

    </div>

    <div className="container" data-aos="fade-up">

    

      <div className="row gy-4">

        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
          <div className="service-box blue">
          <img src={s1} className="img-fluid rounded" alt="" /> 
          <div className="nnnk">
          <h3>Excavation, Earth Works & Landscaping</h3>
          <p>Our team specializes in excavation, earthworks, and landscaping services, ensuring the foundational elements of your project are executed with precision and expertise. From site preparation to terrain shaping, we handle each step meticulously, guaranteeing optimal conditions for your construction endeavors while enhancing the natural beauty of the surroundings.</p>
         
          </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
          <div className="service-box orange">
            <img src={s2} className="img-fluid rounded" alt="" /> 
            <div className="nnnk">
            <h3>Construction Machinery Rentals</h3>
            <p>Access to a comprehensive range of construction machinery rentals is at your fingertips. Whether it's excavators, bulldozers, or cranes, our diverse fleet of equipment is meticulously maintained and readily available to support your project requirements, empowering you with the tools necessary for efficient and seamless operations.</p>
           
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
          <div className="service-box green">
            <img src={s3} className="img-fluid rounded" alt="" /> 
            <div className="nnnk">
            <h3>Building construction</h3>
            <p>Building construction is where our passion and proficiency converge. With a commitment to quality craftsmanship and attention to detail, we transform blueprints into reality, delivering structurally sound and aesthetically pleasing buildings tailored to your specifications and exceeding your expectations.</p>
           
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
          <div className="service-box red">
            <img src={s4} className="img-fluid rounded" alt="" /> 
            <div className="nnnk">
            <h3>Water Proofing works</h3>
            <p>Protecting your investments from water damage is paramount, and our waterproofing solutions are engineered to deliver unparalleled durability and resilience. From basements to rooftops, we employ innovative techniques and premium materials to safeguard your structures against moisture infiltration, ensuring longevity and peace of mind.</p>
           
            </div>
          </div>
        </div>

        <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
          <div className="service-box purple">
            <img src={s5} className="img-fluid rounded" alt="" /> 
            <div className="nnnk">
            <h3>Finishing works</h3>
            <p>Elevate the visual appeal and functionality of your spaces with our meticulous finishing works. From interior fit-outs to exterior detailing, our skilled craftsmen apply the finest finishes and materials, enhancing the overall aesthetic appeal and usability of your project, leaving a lasting impression on every facet of the design.</p>
           
            </div>
          </div>
        </div>


      </div>

    </div>

  </section>
    
    </div>
  )
}

export default Service