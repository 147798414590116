
import './App.css';
import { Routes,Route } from 'react-router-dom';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from './Pages/Header';
import Home from './Pages/Home';
import Blog from './Pages/Blog';
import Contactus from './Pages/Contact_us';


import Footer from './Pages/Footer';
import About from './Pages/About';
import Projects from './Pages/Projects';
import BlogDetail from './Pages/BlogDetail';
import Notfound from './Pages/Notfound';


function App() {
  return (
    
    <Routes>
      <Route path='/' element={<div><Header/><Home/></div>} />
      
      <Route path='/blog' element={<div><Header/><Blog/><Footer/></div>} />
      <Route path='/contact' element={<div><Header/><Contactus/><Footer/></div>} />
      <Route path='/portfolio' element={<div><Header/><Projects/><Footer/></div>} />
      <Route path='/about' element={<div><Header/><About/><Footer/></div>} />
      <Route path='*' element={<div><Notfound/></div>} />
      <Route path='/blogdetail/:id' element={<div><Header/><BlogDetail/><Footer/></div>}/>
    </Routes>
  
  );
}

export default App;
