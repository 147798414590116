import React from 'react'
import HomeAbout from './HomeAbout'

import Homeblog from './Homeblog'

import Footer from './Footer'
import  banner1 from '../i/banner1.jpg'
import  banner3 from '../i/banner3.jpeg'


import Service from './Service'
import HomeProject from './HomeProject'

function Home() {
  return (
    <div >
   


    <div className="slider-detail mt-0">

        <div id="carouselExampleIndicators" className="carousel slide " data-ride="carousel">
            <ol className="carousel-indicators ">

            </ol>
            <div className="carousel-inner">
                <div className="carousel-item ">
                    <img className="d-block w-100" src={banner1} alt=""/>
                    <div className="carousel-caption fvgb  d-block">
                        <h1 className="animated bounceInDown fdn">Where Vision Meets Reality: Transforming Spaces, Building Dreams </h1>
                        

               
                    </div>
                </div>

                <div className="carousel-item active">
                    <img className="d-block w-100" src={banner3} alt=""/>
                    <div className="carousel-caption fvgb  d-block">
                        <h1 className="animated bounceInDown fdn">Crafting Foundations for Your Future</h1>
                       

                       
                    </div>
                </div>

            </div>
            <a className="carousel-control-prev naolaal" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"><i className="bi bi-caret-left  "></i></span>
               
            </a>
            <a className="carousel-control-next  naolaal" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"><i className="bi bi-caret-right"></i></span>

            </a>
        </div>


    </div>
    
    <HomeAbout/>
    
   <Service/>

<Homeblog/>

<HomeProject/>
<Footer/>
    </div>
  )
}

export default Home