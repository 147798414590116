import React, { useEffect, useState } from 'react'
 
import axios from 'axios';

function Team() {


    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("http://localhost:8081/teamfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);

  return (
    <div>
    <div className="container-xxl py-5">
        <div className="container">
           {data.length >0 && <div className="text-center mb-5 fadeInUp" data-wow-delay="0.1s">
               
                <h1 className="mb-2">Explore Our <span className='text-primary'>STAFFS</span></h1>
            </div>}


            
           
            
            <div className='row image-sliderd d-flex justify-content-center '>
            { data.map((members,i)=>{
            return   <div className='center col-md-3' key={i}>
            
            <div className=" team-item border mb-4 shadow rounded" >
                <div className="overflow-hidden">
                <img className="img-fluid row_poster rounded" src={'http://localhost:8081/images/' + members.Image} alt=""/>
                </div>
              
                <div className="text-center p-4 shadow">
                    <h4 className="mb-1 ">{members.Name}</h4>
                    
                    <h5>{members.Position}</h5>
                </div>
            </div>
            </div> 
             
             
        
           

            }) }
            </div>  
             
              


           
        </div>
    </div>
    
    
    </div>
  )
}

export default Team