import React, { useState } from 'react'


import axios from 'axios'
import Footer from './Footer'
import { Link } from 'react-router-dom'

function Contact_us() {
    const [data, setdata] = useState({

        name:"",
        email:'',
        phone:'',
        subject:'',
        message:''
    });
    const [resp, setresp] = useState(null);
    
    const handlecontact=(e)=>{
        e.preventDefault()
       
        axios.post('https://backend.nxtconstructions.gov.et/contact', {name:data.name,phone:data.phone,email:data.email,subject:data.subject,message:data.message})
          .then(res => {
            if (res.data.status==="success") {
            setresp(res.data.status)
            setdata({name:"",phone:"",email:"",subject:"",message:""})
            }
          })
          .catch(err => console.log(err))
    
    
    }
    


  return (
    <div>
    <div class="page-nav page-header no-margin w-100 mx-0 row">
    <div class="container w-100">
        <div className="row">
        <h2 className="text-white ">Nxt Constructions</h2>
            <ul>
                <li > <Link to={'/'} className="text-white ld"><i className="bi bi-caret-right text-white"></i> Home</Link></li>
                <li className="text-white "><i className="bi bi-caret-right text-white"></i>Contact Us</li>
            </ul>
        </div>
    </div>
    </div>


    <div className="container-xxl py-5">
    <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">

            <h1 className="mb-5"><span className="text-primary text-uppercase">Contact Us</span> For Any Query</h1>
        </div>
        <div className="row g-4">
            <div className="col-12">
                <div className="row gy-4">
                    <div className="col-md-4 text-center">
                        <h6 className="section-title  text-primary text-uppercase text-center">Phone Numbers</h6>
                        <div><i className="fa fa-envelope-open text-primary me-2"></i>0974947373</div>
                        <div><i className="fa fa-envelope-open text-primary me-2"></i>0911599648</div>
                    </div>
                    <div className="col-md-4 text-center">
                        <h6 className="section-title text-start text-primary text-uppercase">Email</h6>
                        <div><i className="fa fa-envelope-open text-primary me-2"></i>tekrim17@gmail.com</div>
                    </div>
                    <div className="col-md-4 text-center">
                        <h6 className="section-title text-start text-primary text-uppercase">Address</h6>
                        <div><i className="fa fa-envelope-open text-primary me-2"></i>Lebu, Addis Ababa, Ethiopia</div>
                    </div>
                </div>
            </div>
            <div className="col-md-6 wow fadeIn " data-wow-delay="0.1s">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4423.172311801504!2d38.71818101640613!3d8.949125254131614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b81953f85b2cb%3A0x468efec7f134772e!2sSara%20Building!5e0!3m2!1sen!2set!4v1716839003815!5m2!1sen!2set" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="col-md-6 bg-dark p-4  rounded">
                <div className="wow fadeInUp" data-wow-delay="0.2s">
                    <form onSubmit={handlecontact} >
                        <div className="row ">
                        { resp && <div className="alert alert-success" role="alert">
                        <strong>Your message is delivered!  Thanks for contacting us  </strong>
                    </div>}
                            <div className="col-md-6">
                                <div className="">
                                    <input type="text" className="form-control shadow border-black" id="name" placeholder="Your Name"
                                    value={data.name} onChange={e => setdata({ ...data, name: e.target.value })}
                                    />
                         
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <input type="email" className="form-control shadow border-8 border-black" id="email" placeholder="Your Email"
                                    value={data.email} onChange={e => setdata({ ...data, email: e.target.value })}
                                    />
                                
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="">
                                    <input type="number" className="form-control shadow border-black" id="subject" placeholder="Phone number"
                                    value={data.phone} onChange={e => setdata({ ...data, phone: e.target.value })}
                                    />
                                   
                                </div>
                            </div>

                            <div className="col-md-6">
                            <div className="">
                                <input type="text" className="form-control shadow border-black" id="subject" placeholder="Subject"
                                value={data.subject} onChange={e => setdata({ ...data, subject: e.target.value })}
                                />
                               
                            </div>
                        </div>
                            <div className="col-12">
                                <div className="">
                                    <textarea className="form-control shadow border-black" placeholder="Leave a message here" id="message" style={{height: '70px'}}
                                    value={data.message} onChange={e => setdata({ ...data, message: e.target.value })}
                                    ></textarea>
                                   
                                </div>
                            </div>
                            <div className="col-12">
                                <button  type="submit" className="btn btn-warning w-100" >Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


    
    


    </div>
  )
}

export default Contact_us