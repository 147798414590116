import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import axios from 'axios';
function HomeProject() {

    const [data, setdata] = useState([]);

    
    useEffect(() => {
        axios.get("https://backend.nxtconstructions.gov.et/portfoliofetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 3))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);

  return (
    <div>
    
{data.length>0 &&<div className="container-fluid py-5">
<div className="container py-3">
    <div className="text-center mb-5 wow fadeInUp" data-wow-delay=".3s">
        <h2 className="mb-2  text-dark ">Recently completed <span className='text-primary'>projects</span></h2>

    </div>
    <div className="row g-5">
    {data.map((project,i)=>{
        return  <div className="col-xxl-4 col-lg-4 col-md-4 col-sm-12 wow fadeInUp" data-wow-delay=".3s" key={i}>
            <div className="project-item">
                <div className="project-left bg-dark"></div>
                <div className="project-right bg-dark"></div>
                <img src={'https://backend.nxtconstructions.gov.et/images/' + project.image} className="img-fluid h-100" alt="img"/>
                
                <Link  className="fs-4 fw-bold  nnnbbjj text-center d-flex  flex-column nblure">
                <h3 className='text-white'><b><i className="bi bi-buildings"></i>  {project.name}</b></h3>
                
                <h6 className='text-white'><b><i className="bi bi-geo-alt-fill"></i>  {project.location}</b></h6>
                
                
                </Link>
            </div>
        </div>
            
    
    
        })}
        
        
    </div>
</div>
</div>
}

    
    </div>
  )
}

export default HomeProject