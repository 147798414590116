import React, { useEffect, useState } from 'react'

import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
function BlogDetail() {
    const [data, setdata] = useState([]);
    const [latest, setlatest] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        axios.get('https://backend.nxtconstructions.gov.et/detail/' + id)
          .then(res => {
            if (res.data.status === "success") {
              setdata(res.data.Result)
             
            }
          })
          .catch(err => console.log(err))
      }, [id]);



      useEffect(() => {
        axios.get("https://backend.nxtconstructions.gov.et/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
      
        setlatest(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 4))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);
  return (
    <div>
    <div class="page-nav page-header no-margin row">
    <div class="container">
        <div class="row">
            <h2 class="text-white ">Nxt Constructions</h2>
            <ul>
           
                <li > <Link to={'/blog'} class="text-white ld"><i class="bi bi-caret-right text-white"></i>Blog</Link></li>
                <li class="text-white "><i class="bi bi-caret-right text-white"></i> Blog Detail</li>
            </ul>
        </div>
    </div>
</div>
    

  <section id="blog" class="blog">
  <div class="container" data-aos="fade-up">

    <div class="row">

      <div class="col-lg-8 entries">

      {data.map((detail,i)=>{
		return	  <article class="entry entry-single" key={i}>

          <div class="entry-img">
            <img src={'https://backend.nxtconstructions.gov.et/images/' + detail.image} alt="" class="img-fluid"/>
          </div>

          <h2 class="entry-title">
            <Link className='dec text-decoration-none text-dark'>{detail.subject}</Link>
          </h2>

          <div class="entry-meta">
            <ul>
              
              <li class="d-flex align-items-center"><i class="bi bi-clock"></i> <Link className='dec text-decoration-none '><time datetime="2020-01-01">{detail.date}</time></Link></li>
             
            </ul>
          </div>

          <div class="entry-content">
            <p>
             {detail.detail}
            </p>

           

         

         {detail.detail2 &&   <p>
              {detail.detail2}
            </p>}


         

          </div>

         
        </article>

    })}

        

      </div>
      <div class="col-lg-4 mr-0 px-0">

      <div class="sidebar mx-0">

        


        <h3 class="sidebar-title text-dark">Recent Posts</h3>
        <div class="sidebar-item recent-posts">
        {latest.map((blog,i)=>{
          return   <div class="post-item clearfix" key={i}>
          <Link to={`/blogdetail/${blog.id}`} className='text-decoration-none '>
             <img src={'https://backend.nxtconstructions.gov.et/images/' + blog.image} alt=""/>
             <h4><h6 class="text-decoration-none text-dark">{blog.subject}</h6></h4>
             <time datetime="2020-01-01">{blog.date}</time>
             </Link>
           </div>
        }) }
      
         

  

   

      </div>

    </div>

  </div>


    </div>

  </div>
</section>


    
    </div>
  )
}

export default BlogDetail