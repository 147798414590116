import React from 'react'
import {Link} from 'react-router-dom'

import  about from '../i/logo.jpg'

function Header() {
  return (
    <div>
    
    <div className="nav-bar " style={{backgroundColor:'white'}}>
<div className="container-fluid">
    <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
    <Link to={'/'} className="loo bolded text-decoration-none ">
    <img src={about} className="img-fluid rounded-circle logonfc" alt="" /> 
  
  </Link>
  <button type="button" className="navbar-toggler nananan" data-toggle="collapse" data-target="#navbarCollapse">
  <span className="bi bi-list nananan h1"></span>
</button>

        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
   

           
            <div className="navbar-nav mr-auto nab">
                <Link to={'/'} className="nav-item nav-link " data-toggle="collapse" data-target="#navbarCollapse"><i className="bi bi-house"></i> HOME</Link>
                <Link to={'/about'} className="nav-item nav-link " data-toggle="collapse" data-target="#navbarCollapse" >ABOUT</Link>
                <Link to={'/portfolio'}  className="nav-item nav-link" data-toggle="collapse" data-target="#navbarCollapse">PROJECTS</Link>
                <Link to={'/'} className="nav-item nav-link" data-toggle="collapse" data-target="#navbarCollapse">SERVICES</Link>
                <Link to={'/about'} className="nav-item nav-link" data-toggle="collapse" data-target="#navbarCollapse">TEAM</Link>
               
              
                <Link to={'/blog'} className="nav-item nav-link" data-toggle="collapse" data-target="#navbarCollapse">BLOG</Link>
                <Link to={'/contact'} className="nav-item nav-link lllll" data-toggle="collapse" data-target="#navbarCollapse">CONTACT</Link>
            </div>
            
            <div className="ml-auto mmmmm">
            <Link to={'/contact'} className="btn" >Contact us</Link>
            </div>
        </div>
    </nav>
</div>
</div>
    
    
    </div>
  )
}

export default Header